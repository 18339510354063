import React from "react"
import Layout from "../components/layout"
import './style/duo.scss'
import duoImg1 from '../images/duo/Chiquito 08 1duo.png';
import duoImg2 from '../images/duo/Chiquito 08 2duo.png';
const Duo = () => {
  return(
    <>
      <Layout className="layout layout-loaded">
        <section className="duo hero">
          <div>
            <span>DUO</span>
            <p><span>« Faire beau et bien,</span>
              <span>entre tradition et</span>
              <span>innovation. »</span></p>
          </div>
          <div>
            <picture>
              <source srcSet={duoImg1} type="image/jpeg" />
              <img src={duoImg1} alt="background"/>
            </picture>
          </div>
        </section>
        <section className="duo">
          <div className="section-1">
            <div>
              <img src={duoImg2} alt="duo" />
            </div>
            <div>
              <p data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Depuis 2008, nous évoluons dans la même sphère d’aventures qui nous sont proposées. Nos débuts se font sous la direction de Franck Pommery, un homme plein de bon sens et d’ingéniosité, ce qu’il faut de vieille école, qui nous donne l’opportunité de toucher à tout et agile en toutes situations.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Jusqu’en 2016 nous avons eu l’occasion de concevoir et réaliser des projets d’envergure dans un registre haut de gamme, où chaque détail compte.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Il y eût une grande période Plaza Athénée dont le Spa Dior au sous-sol avec Patrick Ribes, la rénovation des hébergements de style Art Déco et Classique avec Marie-José Pommereau, le programme de rénovation et d’extension des 100 ans avec le suivi de la Grande Galerie et du Ballroom conçus par Bruno Moinard, et du bar conçus par Patrick Jouin.
              </p>
            </div>
          </div>
          <div className="section-2">
            <div>
              <p data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease"
                 className="exp">
                <span>« 10 ans</span>
                <span>d’expériences</span>
                <span>communes dans le</span>
                <span>haut de gamme »</span>
              </p>
            </div>
            <div>
              <p data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Depuis nous réalisons en neuf, comme en rénovation, des maisons et appartements exigents une haute attention, à Paris comme à Sancerre avec ce terroir si discret et pourtant si passionnant.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Dans le domaine hôtelier nous avons aussi accompagné la Famille Chambon dans la rénovation de la Villa Maillot et la création de la Villa Haussmann et son spa, de nouveau avec Patrick RIBES et Amélie Carrafang.  Dans le même temps, nous avons pu participer à la création du Mandarin Oriental avec notamment le suivi du restaurant et du bar par Patrick Jouin et sous la direction de Plendi, filière prestige de Vinci.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Le milieu de l’hôtellerie nous a permis de rencontrer Alain Ducasse, de rénover le restaurant Allard et son appartement à Paris avec l’agence japonaise Simplicity.	 Nouvelle page blanche en 2017 avec notre association au sein de Dorénavant Studio, résultat de ces 10 années d’expériences communes et toutes ces rencontres si enrichissantes.
              </p>
            </div>
          </div>
        </section>
      </Layout>
    </>
   )
}

export default Duo
